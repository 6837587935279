<template>
  <div class="footer">
    <div class="footer py-5 text-center">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12">
            <p class="mb-0">
              <!-- <a href="#" tarket="_blank" class="p-3"><span class="icon-facebook"></span></a>
              <a href="#" tarket="_blank" class="p-3"><span class="icon-twitter"></span></a>
              <a href="#" tarket="_blank" class="p-3"><span class="icon-instagram"></span></a>
              <a href="#" tarket="_blank" class="p-3"><span class="icon-linkedin"></span></a> -->
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="mb-0">
            <pre>Clickry &copy; 2020 All rights reserved.</pre>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld'
}
</script>

