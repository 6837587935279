import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'CLIKRY | HOME | Management Services and Digital Solutions',
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: 'CLIKRY | ABOUT | Management Services and Digital Solutions',
    },
    component: () => import('../views/About/About.vue')
  },
  {
    path: '/teams',
    name: 'Teams',
    meta: {
      title: 'CLIKRY | TEAMS | Management Services and Digital Solutions',
    },
    component: () => import('../views/About/Teams.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'CLIKRY | CONTACT | Management Services and Digital Solutions',
    },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/whatwedo',
    name: 'WhatWeDo',
    meta: {
      title: 'CLIKRY | What We Do | Management Services and Digital Solutions',
    },
    component: () => import('../views/WhatWeDo.vue')
  },
  {
    name: 'AdvisoryConsulting',
    path: '/advisory-consulting',
    meta: {
      title: 'CLIKRY | Advisory Consulting | Management Services and Digital Solutions',
    },
    component: () => import('../views/Services/AdvisoryConsulting.vue')
  },
  {
    name: 'MergersandAcquisition',
    path: '/mergers-and-acquisition',
    meta: {
      title: 'CLIKRY | Mergers and Acquisition | Management Services and Digital Solutions',
    },
    component: () => import('../views/Services/MergersAcquisition.vue')
  },
  {
    name: 'StrategyWorkshop',
    path: '/strategy-workshop',
    meta: {
      title: 'CLIKRY | Strategy Workshop | Management Services and Digital Solutions',
    },
    component: () => import('../views/Services/StrategyWorkshop.vue')
  },
  {
    name: 'DigitalBusinessSolutions',
    path: '/digital-business-solutions',
    meta: {
      title: 'CLIKRY | Digital Business Solutions | Management Services and Digital Solutions',
    },
    component: () => import('../views/Services/DigitalBusinessSolutions.vue')
  }
  /*{
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services/Services.vue'),
    children: [
      {
        path: '',
        name: 'Products',
        component: () => import('../views/Services/SEOPopularity.vue')
      },
      {
        path: '/advisory-consulting',
        name: 'Advisory Consulting',
        component: () => import('../views/Services/SEOPopularity.vue')
      },
      {
        path: '/mergers-and-acquisition',
        name: 'Mergers and Acquisition',
        component: () => import('../views/Services/MergersAcquisition.vue')
      }
    ]
  }*/
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active-link',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
