<template>
  <div class="home">
    <div class="site-blocks-cover" style="overflow: hidden;">
      <div class="container">
        <div class="row align-items-center justify-content-center">

          <div class="col-md-12" style="position: relative; margin-top: 100px;" data-aos="fade-up" data-aos-delay="200">
            
            <img src="images/undraw_data_report_bi6l.svg" alt="Image" class="img-fluid img-absolute">

            <div class="row mb-4" data-aos="fade-up" data-aos-delay="200">
              <div class="col-lg-8 mr-auto">
                <h1 class="text-dark-blue">Management Services and Digital Solutions</h1><br>
                <h2 class="typerclz"><strong>
                <vue-typer
                   :text='["Strategic Management", "M&A Advisory Services" , "Data Transformation", "Data Engineering", "Real Time Analytics", "Cloud Migration", "Business Digital Platform", "Business Workflow Automation", "Application Managed Services", "IoT App", "Mobile App", "Web App", "Custom Software development",]'
                   :repeat='10'
                   :shuffle='false'
                   initial-action='typing'
                   :pre-type-delay='70'
                   :type-delay='70'
                   :pre-erase-delay='2000'
                   :erase-delay='250'
                   erase-style='select-all'
                   :erase-on-complete='false'
                   caret-animation='blink'
                ></vue-typer>
                 </strong></h2><br>
                <div>
                  <router-link class="btn btn-primary mr-2 mb-2 bg-blue" to="/whatwedo">What We Do</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
    <div class="site-section">
      <div class="container">
        <!-- <div class="row mb-5 justify-content-center text-center"  data-aos="fade-up">
          <div class="col-7 text-center  mb-5">
            <h2 class="section-title text-normal-blue">Imagine Features</h2>
          </div>
        </div> -->
        <div class="row align-items-stretch">
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">         
            <div class="unit-4 d-block">
              <div class="unit-4-icon mb-3">
                <span class="icon-wrap"><span class="text-primary icon-bullseye"></span></span>
              </div>
              <div>
                <h3>Strategy Workshop</h3>
                <p>Gain new knowledge and strategy from our leadership workshop</p>
                <router-link class="nav-link" to="/strategy-workshop">Click here..</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="unit-4 d-block">
              <div class="unit-4-icon mb-3">
                <span class="icon-wrap"><span class="text-primary icon-autorenew"></span></span>
              </div>
              <div>
                <h3>M & A Consulting</h3>
                <p>Increase your company valuation with our M&A Advisory Services </p>
                <router-link class="nav-link" to="/mergers-and-acquisition">Click here..</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up"  data-aos-delay="200">
            <div class="unit-4 d-block">
              <div class="unit-4-icon mb-3">
                <span class="icon-wrap"><span class="text-primary icon-store_mall_directory"></span></span>
              </div>
              <div>
                <h3>Digital Business Solutions</h3>
                <p>Provide connected platform for customer and staff with our digital solutions</p>
                <router-link class="nav-link" to="/digital-business-solutions">Click here..</router-link>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
            <div class="unit-4 d-block">
              <div class="unit-4-icon mb-3">
                <span class="icon-wrap"><span class="text-primary icon-settings"></span></span>
              </div>
              <div>
                <h3>Managed Services</h3>
                <p>Enterprise Storage, Data Recovery, Data Protection and Application Management</p>
                <router-link class="nav-link" to="/">Click here..</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="unit-4 d-block">
              <div class="unit-4-icon mb-3">
                <span class="icon-wrap"><span class="text-primary icon-bar-chart"></span></span>
              </div>
              <div>
                <h3>Data Science & Engineering</h3>
                <p>Data engineer & integration for business, operational, predictive analytics</p>
                <router-link class="nav-link" to="/">Click here..</router-link>
              </div>
            </div>   
          </div> 
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="unit-4 d-block">
              <div class="unit-4-icon mb-3">
                <span class="icon-wrap"><span class="text-primary icon-cloud"></span></span>
              </div>
              <div>
                <h3>MultiCloud Migration</h3>
                <p>Application modernization with multicloud storage and compute services </p>
                <router-link class="nav-link" to="/">Click here..</router-link>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer
  }
};
</script>

<style>
.vue-typer {
  font-family: 'Cedarville Cursive', cursive;
  font-size: 42px;
}
.vue-typer .custom.char.typed {
  color: #25aae1; /*#a24415;*/
}
.vue-typer .custom.char.selected {
  color: gray;
}

@media (max-width: 991.98px) {
  .vue-typer {
    font-size: 1rem; } 
}
</style>
