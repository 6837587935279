<template>
  <div id="app">
    <div id="overlayer"></div>
    <div class="loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="site-wrap"  id="home-section">

    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>
    <header class="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">
      <div class="container">
        <div class="row align-items-center">         
          <div class="col-6 col-md-3 col-xl-2  d-block">
            <!-- <h1 class="mb-0 site-logo"><a href="/" class="text-black h2 mb-0">Clikry<span class="text-primary">.</span> </a></h1> -->
            <a href="/" class="mb-0"><img src="images/logo.png"></a>
          </div>
          <div class="col-12 col-md-9 col-xl-10 main-menu">
            <nav class="site-navigation position-relative text-right" role="navigation">
              <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block ml-0 pl-0">
                <li>
                    <router-link class="nav-link" to="/">HOME</router-link>
                </li>
                <li class="has-children">
                  <a href="javascript:void(0)" class="nav-link">SERVICES</a><!-- PRODUCTS & SOLUTIONS -->
                  <ul class="dropdown arrow-top">
                    <li class="has-children">
                      <a href="javascript:void(0)">Business Services</a>
                      <ul class="dropdown">
                        <li><router-link to="/mergers-and-acquisition" class="nav-link">Mergers and Acquisition</router-link></li>
                        <li><router-link to="/advisory-consulting" class="nav-link">Advisory Consulting</router-link></li>
                      </ul>
                    </li> 
                    <!-- <li class="has-children">
                      <a href="javascript:void(0)">Technology Services</a>
                      <ul class="dropdown">
                        <li><a href="#">Storage as Services</a></li>
                        <li><a href="#">Disaster Recovery and data Protection </a></li>
                        <li><a href="#">Business Application Maintenance</a></li>
                      </ul>
                    </li> -->
                  </ul>
                </li>
                <!-- <li class="has-children">
                  <a href="javascript:void(0)" class="nav-link">SOLUTIONS</a>
                  <ul class="dropdown arrow-top">
                    <li class="has-children">
                      <a href="#">Cloud and Big data</a>
                      <ul class="dropdown">
                        <li><a href="#">Multi-Cloud Data Migration </a></li>
                        <li><a href="#">Data Transformation and Engineering</a></li>
                        <li><a href="#">Cloud Architecture & Design</a></li>
                      </ul>
                    </li>
                    <li class="has-children">
                      <a href="#">Digital Platform</a>
                      <ul class="dropdown">
                        <li><a href="#">Customer Experience</a></li>
                        <li><a href="#">Real time Analytics</a></li>
                        <li><a href="#">Omnichannel Ecommerce </a></li>
                      </ul>
                    </li>
                  </ul>
                </li> -->
                <li><router-link class="nav-link" to="/about">ABOUT US </router-link></li>
                <!-- <li><a href="javascript:void(0)" class="nav-link">BLOG</a></li> -->
                <li><router-link class="nav-link" to="/contact">CONTACT</router-link></li>
              </ul>
            </nav>
          </div>
          <div class="col-6 col-md-9 d-inline-block d-lg-none ml-md-0" ><a href="#" class="site-menu-toggle js-menu-toggle text-black float-right"><span class="icon-menu h3"></span></a></div>
        </div>
      </div>
    </header>
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "App"
}
</script>

<style>
.router-link-active {
  color: #1573a2;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 2s ease;
}

.fade-leave {}

.fade-leave-active {
  transition: opacity 2s ease;
  opacity: 0;
}
</style>